 <template>
	<div class="file-management" ref="fileManagement">
		<div class="form-area" ref="form">
			<el-form
				:inline="true"
				:model="form"
				class="demo-form-inline"
				label-position="left"
				label-width="92px"
			>
				<el-form-item label="原始文件名：">
					<el-input v-model.trim="form.original" placeholder="请输入原始文件名"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" size="small" @click="onSearch">查询</el-button>
				</el-form-item>
			</el-form>
		</div>
		<el-table
			ref="multipleTable"
			:data="tableData"
			style="width: 100%"
			stripe
			:height="tableHeight"
			cell-class-name="cellstyle"
		>
			<el-table-column min-width="15%" prop="id" label="id"></el-table-column>
			<el-table-column min-width="5%"  prop="bucketName" label="存储桶"></el-table-column>
			<el-table-column min-width="25%" label="文件名" prop="fileName" :show-overflow-tooltip="true"> </el-table-column>
			<el-table-column min-width="20%"  label="原始文件名" prop="original" :show-overflow-tooltip="true"></el-table-column>
			<el-table-column min-width="10%"  prop="fileSize" label="文件大小"></el-table-column>
			<el-table-column min-width="5%"  prop="type" label="	文件类型"></el-table-column>
			<el-table-column min-width="20%"  label="操作">
				<template slot-scope="scope">
					<el-button
						icon="el-icon-view"
                        type="text"
                        size="small"
                        sort="info"
						@click="onDownloadfile(scope.row)"
					>查看文件</el-button>
					<el-button
						icon="el-icon-delete"
						type="text"
						size="small"
						sort="danger"
						@click="onDeletefile(scope.row)"
					>删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="pagination" ref="pagination">
			<el-pagination
				background
				layout="total, sizes, prev, pager, next, jumper"
				:total="total"
				:page-size="form.pageSize"
				:current-page="form.currentPage"
				@size-change="onSizeChange"
				@current-change="onCurrentChange"
			></el-pagination>
		</div>
	</div>
</template>

  <script>
// import addfile from "./components/addfile.vue";
import { deleteFiles, getFiles, getFilesPage,IMG_PREFIX } from "@/api/lib/api.js";
export default {
	name: "fileManagement",
	components: {
		// addfile
	},
	data() {
		return {
			tableHeight: 0,
			total: 10,
			tableData: [],
			form: {
				current: 1,
				size: 10,
			},
			multipleSelection: [],
			title: "",
			fileId: null,
			dialogVisible: false,
		};
	},
	methods: {
		// 计算表格高度
		computeHeight() {
			let wholeHeight = this.$refs.fileManagement.clientHeight;
			let formHeight = this.$refs.form.clientHeight;
			let paginationHeight = this.$refs.pagination.clientHeight;
			this.tableHeight =
				wholeHeight - 16 - formHeight - paginationHeight - 10 - 16 - 35;
		},
		// 点击查询
		onSearch() {
			this.form.current = 1;
			this.getFilesPage(this.form);
		},
		//获取数据
		getFilesPage(data) {
			getFilesPage(data).then((res) => {
				if (res.code === 0) {
					this.tableData = res.data.records;
					this.total = res.data.total;
					if (!res.data.records.length && this.form.current !== 1) {
						//如果当前页的数据为0则设置页面为前一页 但是确保当前页不为1
						self.form.current -= 1;
						self.getFilesPage(this.form);
					}
				} else {
					this.tableData = [];
					this.total = 0;
					this.$message.error(res.message);
				}
			});
		},
		onDeletefile(row) {
            const h = this.$createElement;
			this.$msgbox({
				title: "消息",
				type: "warning",
				message: h("p", null, [
					h("span", null, "确定删除 "),
					h("i", { style: "color: #059370" }, row.name),
					h("span", null, " ？"),
				]),
				showCancelButton: true,
				confirmButtonText: "确定",
				cancelButtonText: "取消",
			})
				.then(() => {
					deleteFiles(row.id).then((res) => {
						if (res.code === 0) {
							this.$message.success("删除成功");
							this.getFilesPage();
						} else {
							this.$message.error("删除失败");
						}
					});
				})
				.catch(() => {
					this.$message({
						type: "info",
						showClose: true,
						message: "已取消删除",
					});
				});
        },
        //下载文件
        onDownloadfile(row){
            window.open(`${IMG_PREFIX}/${row.bucketName}/${row.fileName}`);
        },
		// 当前页码改变
		onCurrentChange(page) {
			this.form.current = page;
			this.getFilesPage(this.form);
		},
		// 当前条数改变
		onSizeChange(size) {
			this.form.size = size;
			this.getFilesPage(this.form);
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		// 弹窗关闭时的回调
		onDialogClose(flag) {
			this.dialogVisible = false;
			if (flag) {
				this.getFilesPage(this.form);
			}
		},
	},
	created() {
		this.onSearch();
	},
	mounted() {
		this.$nextTick(() => {
			this.computeHeight();
			window.addEventListener("resize", this.computeHeight, false);
		});
	},
	destroyed() {
		window.removeEventListener("resize", this.computeHeight);
	},
};
</script>
<style lang="scss" scoped>
.file-management {
	width: 100%;
	height: 100%;
	border-radius: 4px;
	padding: 16px 20px;
	box-sizing: border-box;
	.avatarImg {
		vertical-align: middle;
		border-radius: 50%;
	}
}

.theme-light {
	.file-management {
		background: #ffffff;
		box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
	}
}
</style>